// src/pages/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';
import usePageTitle from "../components/usePageTitle";

const Blog = () => {
    usePageTitle("Blog - RixStonGamer");

    const posts = [
        {
            id: 'post1',
            title: 'World Best PUBG/BGMI Player',
            summary: 'An in-depth analysis of the best PUBG/BGMI players in the world and what makes them stand out.',
            image: '/path/to/blog-image1.jpg',
        },
        {
            id: 'post2',
            title: 'Mastering the Art of Streaming',
            summary: 'Tips and tricks to improve your streaming setup and engage your audience effectively.',
            image: '/path/to/blog-image2.jpg',
        },
        {
            id: 'post3',
            title: 'Top 10 Gaming Accessories in 2024',
            summary: 'A curated list of the best gaming accessories to enhance your gaming experience.',
            image: '/path/to/blog-image3.jpg',
        },
    ];

    return (
        <div className="blog">
            <h1 className="blog-title">RixStonGamer Blog</h1>
            <p className="blog-subtitle">Explore the latest articles, tips, and news in gaming and streaming.</p>
            <div className="blog-posts">
                {posts.map((post) => (
                    <article key={post.id} className="blog-post">
                        <img src={post.image} alt={post.title} className="blog-post-image" />
                        <div className="blog-post-content">
                            <h2><Link to={`/blog/${post.id}`}>{post.title}</Link></h2>
                            <p>{post.summary}</p>
                            <Link to={`/blog/${post.id}`} className="read-more">Read More</Link>
                        </div>
                    </article>
                ))}
            </div>
        </div>
    );
};

export default Blog;
