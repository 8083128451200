// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzWg_SVbiYIOmTzqltaT6EqwWHXb4TSh8",
  authDomain: "rixstongamerlogin.firebaseapp.com",
  projectId: "rixstongamerlogin",
  storageBucket: "rixstongamerlogin.appspot.com",
  messagingSenderId: "644790112193",
  appId: "1:644790112193:web:91ac8fe2b8d44be322a940",
  measurementId: "G-JP2FS7RB8Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Authentication

// Export auth
export { auth };
