import React from 'react';
import './Projects.css';
import usePageTitle from "../components/usePageTitle";

const projects = [
  {
    title: 'Tic-Tac-Toe',
    description: 'TicTacToe is a game you can play for free.',
    link: '/project/tic-tac-toe',
  },
  {
    title: 'Todo',
    description: 'Todo is a task manager you can use for free.',
    link: '/project/todo',
  },
  {
    title: 'QR Code Generator',
    description: 'QR Code Generator. Here you can Generator QR Code for free.',
    link: '/project/qr-code-generator',
  },
  {
    title: 'Whack-a-Mole',
    description: 'Whack-a-Mole is a game you can play for free.',
    link: '/project/whack-a-mole',
  },
  {
    title: 'Color Picker',
    description: 'The Best Color Picker. you can use for free.',
    link: '/project/color-picker',
  },
  {
    title: 'Password Generator',
    description: 'The Best Color Picker. you can use for free.',
    link: '/project/password-generator',
  },
];

function Projects() {
  usePageTitle("Projects - RixStonGamer");
  return (
    <div className="projects">
      <h1>My Projects</h1>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <a href={project.link} className="project-link">View More</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
