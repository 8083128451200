import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS for styling
import ReactDOM from 'react-dom';
import App from './App'; // Main App component
import { AuthProvider } from './AuthContext'; // Authentication context provider
import './index.css'; // Custom CSS

// Render the App component, wrapped with AuthProvider, into the root element
ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
