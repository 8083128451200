import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import './ColorPicker.css';
import usePageTitle from "../components/usePageTitle";


const ColorPicker = () => {
  usePageTitle("Color Picker - RixStonGamer");
  const [color, setColor] = useState('#ffffff');
  const [showPicker, setShowPicker] = useState(false);
  const [colorName, setColorName] = useState('White');
  const [colorCode, setColorCode] = useState('#ffffff');
  const [searchTerm, setSearchTerm] = useState('');
  const [copiedColor, setCopiedColor] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const pickerRef = useRef(null);

  const colorOptions = {
    '#ffffff': 'White',
    '#000000': 'Black',
    '#ff0000': 'Red',
    '#00ff00': 'Green',
    '#0000ff': 'Blue',
    '#ffff00': 'Yellow',
    '#ff00ff': 'Magenta',
    '#00ffff': 'Cyan',
    '#800000': 'Maroon',
    '#808000': 'Olive',
    '#008000': 'Dark Green',
    '#800080': 'Purple',
    '#008080': 'Teal',
    '#000080': 'Navy',
    '#c0c0c0': 'Silver',
    '#a52a2a': 'Brown',
    '#ff6347': 'Tomato',
    '#ff1493': 'Deep Pink',
    '#1e90ff': 'Dodger Blue',
    '#d3d3d3': 'Light Gray',
    '#f5f5f5': 'White Smoke',
    '#b22222': 'Fire Brick',
    '#ff4500': 'Orange Red',
    '#2e8b57': 'Sea Green',
    '#4682b4': 'Steel Blue',
    '#6a5acd': 'Slate Blue',
    '#d2691e': 'Chocolate',
    '#ff7f50': 'Coral',
    '#6495ed': 'Cornflower Blue',
    '#dc143c': 'Crimson',
    '#00fa9a': 'Medium Spring Green',
    '#ff8c00': 'Dark Orange',
    '#9932cc': 'Dark Orchid',
    '#8b4513': 'Saddle Brown',
    '#ffb6c1': 'Light Pink',
    '#8a2be2': 'Blue Violet',
    '#20b2aa': 'Light Sea Green',
    '#f08080': 'Light Coral',
    '#40e0d0': 'Turquoise',
    '#ee82ee': 'Violet',
    '#dda0dd': 'Plum',
    '#b0e0e6': 'Powder Blue',
    '#adff2f': 'Green Yellow',
    '#ff69b4': 'Hot Pink',
    '#ffd700': 'Gold',
    '#00ff7f': 'Spring Green',
    '#e0ffff': 'Light Cyan',
    '#deb887': 'Burly Wood',
    '#f0e68c': 'Khaki',
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    setColorCode(color.hex);
    setColorName(colorOptions[color.hex] || 'Custom Color');
    setCopiedColor('');
    setSearchTerm('');
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const copyColor = (color) => {
    navigator.clipboard.writeText(color);
    setCopiedColor(color);
    setPopupMessage('Color code copied to clipboard!');
    setShowPicker(false); // Close the color picker after copying
    setTimeout(() => setPopupMessage(''), 3000); // Hide popup after 3 seconds
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const formattedTerm = term.startsWith('#') ? term : `#${term}`;
    const foundColor = Object.keys(colorOptions).find(
      (key) =>
        colorOptions[key].toLowerCase().includes(term) || key.includes(formattedTerm)
    );

    if (foundColor) {
      setColor(foundColor);
      setColorCode(foundColor);
      setColorName(colorOptions[foundColor]);
    } else {
      setColor('#ffffff');
      setColorCode('#ffffff');
      setColorName('No Match');
    }
  };

  const handleColorClick = (color) => {
    copyColor(color);
    setSearchTerm('');
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="color-picker">
      <h1 className="color-picker-header" style={{ color: color }}>
        Color Picker By RIPPON
      </h1>
      <div className="color-display-container">
        <div
          className="color-display"
          style={{ backgroundColor: color }}
          onClick={togglePicker}
        >
          <div className="color-display-text">
            {colorName}
            <br />
            <span className="color-code">{colorCode}</span>
          </div>
        </div>
        {showPicker && (
          <div className="picker-popup" ref={pickerRef}>
            <button className="close-btn" onClick={togglePicker}>
              &times;
            </button>
            <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
            <button className="copy-btn" onClick={() => copyColor(color)}>
              {copiedColor === color ? 'Copied!' : 'Copy Color'}
            </button>
          </div>
        )}
      </div>
      <input
        type="text"
        placeholder="Search by color name or code"
        className="color-search"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <p className="info-text">
        Click on a color below to automatically copy its code to the clipboard.
      </p>
      <div className="color-list">
        <h3>Top 50 Most Popular Colors:</h3>
        <div className="color-grid">
          {Object.keys(colorOptions).map((code) => (
            <div
              key={code}
              className="color-card"
              style={{ backgroundColor: code }}
              onClick={() => handleColorClick(code)}
            >
              <div className="color-info">
                <span className="color-name">{colorOptions[code]}</span>
                <span className="color-code">{code}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {popupMessage && (
        <div className="popup-message">
          {popupMessage}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
