import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import './styles/App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import TicTacToe from './project/TicTacToe';
import Todo from './project/Todo';
import WhackaMole from './project/WhackaMole';
import ColorPicker from './project/ColorPicker';
import PasswordGenerator from './project/PasswordGenerator';
import QRCodeGenerator from './project/QRCodeGenerator';

function ProtectedRoute({ element: Component, redirectTo = "/login" }) {
  const { user } = React.useContext(AuthContext);
  return user ? <Component /> : <Navigate to={redirectTo} />;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:postId" element={<BlogPost />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route path="/project/tic-tac-toe" element={<TicTacToe />} />
            <Route path="/project/todo" element={<Todo />} />
            <Route path="/project/whack-a-mole" element={<WhackaMole />} />
            <Route path="/project/color-picker" element={<ColorPicker />} />
            <Route path="/project/password-generator" element={<PasswordGenerator />} />
            <Route path="/project/qr-code-generator" element={<QRCodeGenerator />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
