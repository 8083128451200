import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import usePageTitle from "../components/usePageTitle";
import './Profile.css';

const Profile = () => {
  usePageTitle("My Profile - RixStonGamer");
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({
    name: user?.name || '',
    phone: user?.phone || '',
    email: user?.email || '',
    bio: user?.bio || '',
    avatar: user?.avatar || '',
  });

  const handleLogout = () => {
    logout();
    navigate('/signin');
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleChange = (e) => {
    setUpdatedUser({ ...updatedUser, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    // Logic to save the updated user details (e.g., call an API to update user info)
    console.log("Updated user details:", updatedUser);
    setEditing(false);
  };

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      {user ? (
        <div className="profile-details">
          <div className="avatar-container">
            <img src={user.avatar || '/default-avatar.png'} alt="User Avatar" className="avatar" />
          </div>
          {editing ? (
            <div className="profile-edit">
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={updatedUser.name}
                  onChange={handleChange}
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  name="phone"
                  value={updatedUser.phone}
                  onChange={handleChange}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={updatedUser.email}
                  onChange={handleChange}
                />
              </label>
              <label>
                Bio:
                <textarea
                  name="bio"
                  value={updatedUser.bio}
                  onChange={handleChange}
                  rows="4"
                />
              </label>
              <label>
                Avatar URL:
                <input
                  type="text"
                  name="avatar"
                  value={updatedUser.avatar}
                  onChange={handleChange}
                />
              </label>
              <button onClick={handleSave} className="save-button">Save Changes</button>
              <button onClick={handleEditToggle} className="cancel-button">Cancel</button>
            </div>
          ) : (
            <div>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Phone:</strong> {user.phone}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Bio:</strong> {user.bio || 'No bio available'}</p>
              <button onClick={handleEditToggle} className="edit-button">Edit Profile</button>
            </div>
          )}
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      ) : (
        <p>Please sign in to view your profile.</p>
      )}
    </div>
  );
};

export default Profile;
