import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ResetPassword.css'; // Make sure you create this CSS file
import usePageTitle from "../components/usePageTitle";

const ResetPassword = () => {
  usePageTitle("Reset Password - RixStonGamer");

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = formData;

    // Simple validation: check if passwords match
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Reset password logic here
      // Simulate a request to the backend to reset the password
      const response = await fakeApiRequestToResetPassword(newPassword);

      if (response.success) {
        setSuccess("Your password has been reset successfully.");
        setError('');

        // Simulate sending a confirmation email
        await fakeApiSendConfirmationEmail();

        // Redirect or give user feedback
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const fakeApiRequestToResetPassword = async (newPassword) => {
    // This is a fake API request. Replace this with your actual API request logic.
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ success: true });
      }, 1000);
    });
  };

  const fakeApiSendConfirmationEmail = async () => {
    // This is a fake API request to send an email. Replace this with your actual email sending logic.
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("Confirmation email sent!");
        resolve(true);
      }, 500);
    });
  };

  return (
    <div className="reset-password">
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          required
        />

        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <button type="submit">Reset Password</button>
        <p>
          Remembered your password? <Link to="/signin">Sign In</Link>
        </p>
      </form>
    </div>
  );
};

export default ResetPassword;
