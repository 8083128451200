import React, { useState, useEffect } from 'react';
import './WhackaMole.css';
import usePageTitle from "../components/usePageTitle";

const WhackaMole = () => {
  usePageTitle("WhackaMole - RixStonGamer");

  const [moles, setMoles] = useState(Array(9).fill(false));
  const [score, setScore] = useState(0);
  const [missedMoles, setMissedMoles] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [highestScore, setHighestScore] = useState(0);
  const [lastScore, setLastScore] = useState(0);

  useEffect(() => {
    // Load the highest score from local storage
    const storedHighestScore = localStorage.getItem('highestScore');
    const storedLastScore = localStorage.getItem('lastScore');
    if (storedHighestScore) {
      setHighestScore(parseInt(storedHighestScore));
    }
    if (storedLastScore) {
      setLastScore(parseInt(storedLastScore));
    }
  }, []);

  useEffect(() => {
    if (gameStarted && !gameOver && !isPaused) {
      const interval = setInterval(() => {
        const newMoles = Array(9).fill(false);
        const randomIndex = Math.floor(Math.random() * 9);
        newMoles[randomIndex] = true;
        setMoles(newMoles);

        if (moles.includes(true)) {
          setMissedMoles(missedMoles + 1);
        }

        if (missedMoles >= 5) {
          setGameOver(true);
          setMoles(Array(9).fill(false)); // Clear moles
        }
      }, 600);
      return () => clearInterval(interval);
    }
  }, [moles, gameOver, isPaused, gameStarted, missedMoles]);

  const handleMoleClick = (index) => {
    if (gameStarted && !isPaused && moles[index]) {
      setScore(score + 1);
      const newMoles = [...moles];
      newMoles[index] = false;
      setMoles(newMoles);
    }
  };

  const startGame = () => {
    setScore(0);
    setMissedMoles(0);
    setGameOver(false);
    setIsPaused(false);
    setGameStarted(true);
  };

  const resetGame = () => {
    setScore(0);
    setMissedMoles(0);
    setGameOver(false);
    setIsPaused(true);
    setGameStarted(false);
    setMoles(Array(9).fill(false));
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const exitGame = () => {
    window.location.href = '/projects';
  };

  useEffect(() => {
    if (gameOver) {
      // Save the last score
      setLastScore(score);
      localStorage.setItem('lastScore', score);

      // Update and save the highest score
      if (score > highestScore) {
        setHighestScore(score);
        localStorage.setItem('highestScore', score);
      }
    }
  }, [gameOver, score, highestScore]);

  return (
    <div className="whack-a-mole-container">
      <h1 className="game-title">Whack-a-Mole</h1>
      
      <div className="top-scores">
        <div className="last-score">Last Score: {lastScore}</div>
        <div className="highest-score">Highest Score: {highestScore}</div>
      </div>
      
      <div className="score-board">Score: {score}</div>
      <div className="missed-board">Missed: {missedMoles}/5</div>

      <div className={`grid ${!gameStarted ? 'disabled' : ''}`}>
        {moles.map((mole, index) => (
          <div
            key={index}
            className={`mole ${mole ? 'active' : ''}`}
            onClick={() => handleMoleClick(index)}
          >
            {mole && <div className="mole-img"></div>}
          </div>
        ))}
      </div>

      {!gameStarted ? (
        <button className="start-button" onClick={startGame}>Start Game</button>
      ) : (
        <>
          {gameOver ? (
            <div className="game-over-popup">
              <h2>Game Over</h2>
              <p>Your Score: {score}</p>
              <button className="reset-button" onClick={resetGame}>Restart Game</button>
              <button className="exit-button" onClick={exitGame}>Exit Game</button>
            </div>
          ) : (
            <button className="pause-button" onClick={togglePause}>
              {isPaused ? 'Resume Game' : 'Pause Game'}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default WhackaMole;
