import React from 'react';
import { FaInstagram, FaDiscord, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="social-icons">
          <a href="https://www.instagram.com/rixstongamer/" target="_blank" rel="noopener noreferrer" className="icon instagram">
            <FaInstagram size={24} title="Instagram" />
          </a>
          <a href="https://discord.gg/uMNyVRYydZ" target="_blank" rel="noopener noreferrer" className="icon discord">
            <FaDiscord size={24} title="Discord" />
          </a>
          <a href="https://www.youtube.com/@RixStonGamer?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="icon youtube">
            <FaYoutube size={24} title="YouTube" />
          </a>
          <a href="https://whatsapp.com/channel/0029VaNfZUY3GJP3VMek2m3z" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
            <FaWhatsapp size={24} title="WhatsApp" />
          </a>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} RixStonGamer. All rights reserved.</p>
          <a href="https://docs.google.com/document/d/1D-NTCehbKc9OoZnyDwWXx81jgpwQAbkgYZcvd40moks/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="privacy-policy">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
