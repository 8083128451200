import React from 'react';
import { useParams, Link } from 'react-router-dom';
import usePageTitle from '../components/usePageTitle';
import './BlogPost.css';

const posts = {
    post1: {
        title: 'World Best PUBG/BGMI Player',
        content: 'In-depth content about the best PUBG/BGMI players, their strategies, and gameplay techniques.',
    },
    post2: {
        title: 'Mastering the Art of Streaming',
        content: 'Discover how to set up your streaming environment, engage your audience, and grow your channel.',
    },
    post3: {
        title: 'Top 10 Gaming Accessories in 2024',
        content: 'A curated list of the best gaming accessories that can enhance your gaming experience in 2024.',
    },
    // Add more posts as needed
};

const BlogPost = () => {
    const { postId } = useParams();
    const post = posts[postId];

    usePageTitle(post ? `${post.title} - RixStonGamer` : 'Post Not Found - RixStonGamer');

    if (!post) {
        return (
            <div className="post-container">
                <h1>Post Not Found</h1>
                <p>Sorry, the post you are looking for does not exist.</p>
                <Link to="/blog" className="back-to-blog">Back to Blog</Link>
            </div>
        );
    }

    return (
        <div className="post-container">
            <h1>{post.title}</h1>
            <p>{post.content}</p>
            {/* Additional content or components can be added here */}
            <Link to="/blog" className="back-to-blog">Back to Blog</Link>
        </div>
    );
};

export default BlogPost;
