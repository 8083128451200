import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check for a stored user in localStorage when the component mounts
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (email, password) => {
    // Mock authentication logic (replace with actual authentication API)
    let userData = null;

    if (email === 'login@rixstongamer.site' && password === '1223334444') {
      userData = { email, role: 'user', name: 'User Name', phone: '123-456-7890', avatar: '/user-avatar.png' };
    } else if (email === 'admin@rixstongamer.site' && password === 'admin1234') {
      userData = { email, role: 'admin', name: 'Admin Name', phone: '987-654-3210', avatar: '/admin-avatar.png' };
    } else {
      return false; // Failed login
    }

    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    return true; // Successful login
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const updateUser = (updatedUserData) => {
    const updatedUser = { ...user, ...updatedUserData };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
